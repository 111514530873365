<template>
  <el-form
    :rules="rules"
    ref="formRef"
    :model="fromData"
    class="shop-improve-from reg-form"
    label-position="top"
  >
  <el-form-item
      label="企业名称"
      prop="companyName"
    >
      <el-input
      v-elInput
        v-model="fromData.companyName"
        type="text"
        class="form-item-input"
        maxlength="100"
        placeholder="请输入您的企业名称"
      />
    </el-form-item>
    <el-form-item
      style="display: none;"
    >
      <el-input
      v-elInput
        type="text"
        class="form-item-input"
        maxlength="30"
        placeholder="请输入联系人姓名"
      />
    </el-form-item>
    <el-form-item
      style="display: none;"
    >
      <el-input
      v-elInput
        type="password"
        class="form-item-input"
        maxlength="30"
        placeholder="请输入联系人姓名"
      />
    </el-form-item>
    <el-form-item
      label="联系人"
      prop="contact"
    >
      <el-input
      v-elInput
        v-model="fromData.contact"
        type="text"
        class="form-item-input"
        maxlength="30"
        placeholder="请输入联系人姓名"
      />
    </el-form-item>

    <el-form-item
        prop="pwd"
        label="设置密码"
    >
      <el-input
      v-elInput
          v-model="fromData.pwd"
          :type='fromData.pwdType'
          class="form-item-input"
          placeholder="请设置密码，6-30位英文/数字/符号"
          onfocus="this.removeAttribute('readonly');"
          maxlength="30"
      >
        <template #suffix>
          <el-icon class="el-input__icon cur_p" style="margin-right: 20px;font-size: 20px" @click.stop='changePwdType'>
            <component :is='fromData.viewPwd'/>
          </el-icon>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item>
      <button type="button" @click='submitForm(formRef)' :disabled="canClick" class="wy-button next-btn login">
        注册完成
      </button>
    </el-form-item>
  </el-form>
</template>

<script  setup>
import {computed, getCurrentInstance, reactive, ref, toRefs, unref , onMounted} from 'vue';
import {registerStepTwo} from "@utils/api/person/index.js";
import {encrypt} from "@utils/tool";
import {formRules} from "../../config"
import {useRoute, useRouter} from "vue-router";
import {loginIn} from "@utils/api/user";
import {setToken} from "@utils/tool/token";
import {Hide, View} from "@element-plus/icons-vue";

const route = useRoute();
const router = useRouter()

const props = defineProps({
  form: {
    type: Object,
    default: () => {
    }
  }
})
const {form} = toRefs(props)
const {proxy} = getCurrentInstance();
const formRef = ref(null)
const fromData = reactive({
  //企业名称
  companyName: '',
  //联系人
  contact: '',
  //邀请码
  invitationCode: '',
  viewPwd: Hide,
  pwdType: 'password',
  pwd:''
})
const canClick = computed(() => {
  return !fromData.companyName || !fromData.contact || !fromData.pwd
})
const rules = reactive({
  companyName: formRules.companyName,
  contact: formRules.contact,
  pwd: formRules.pwd,
});
const changePwdType = () => {
  fromData.pwdType = fromData.pwdType === 'password' ? 'text' : 'password'
  fromData.viewPwd = fromData.pwdType === 'password' ? Hide : View
}
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      const localForm = {...form.value}
      registerStepTwo({
        mobile: localForm.phone,
        password: encrypt(fromData.pwd),
        companyName: fromData.companyName,
        name: fromData.contact,
        mcode: localForm.invitationCode
      }).then(res => {
        if (res.code === 0) {
          proxy.$message.success('注册成功')
          const loginInfo = {
            account: localForm.phone,
            password: encrypt(fromData.pwd)
          }
          loginIn(loginInfo).then(res => {
            if (res.code !== 0) {
              proxy.$message.error(res.msg)
              return
            }
            proxy.$cookies.set('userInfo', res.data, 60 * 60 * 24 * 7)
            setToken(res.data.token, 1)
            router.push({path: '/'})
          })
        } else {
          proxy.$message.error(res.msg)
        }
      })
    } else {
      return false
    }
  })
}

onMounted(() => {
  console.log(fromData)
})
</script>

<style lang="scss">
  @import url(../common.scss);
  .shop-improve-from{
    .el-form-item{
      margin-top: 30px;
    }
    .next-btn{
      margin-top: 66px;
      width: 100%;
      height: 80px;
      font-size: 18px;
    }
  }
</style>