<template>
  <!--  商城注册表单-->
  <el-form
      :rules="rules"
      ref="formRef"
      :model="localForm"
      class="shop-improve-from large-input"
      label-position="top"
  >
    <el-form-item
        prop="phone"
    >
      <el-input type="number" autocomplete="off" v-model="localForm.phone"
                maxlength="13" placeholder="请输入您的手机号" class="form-item-input no-number phone"  v-elInput></el-input>
    </el-form-item>
    <el-form-item
        prop="code"
    >
      <div class="form-item">
        <el-input
            v-model="localForm.code"
            type="number"
            class="form-item-input no-number code"
            placeholder="请输入验证码"
            maxlength="6"
            autocomplete="off"
            v-elInput
        />
        <div class="code-btn" :class="isOK?'':'disabled'" @click.stop='handleClickGetCode'>{{ str }}</div>
      </div>
    </el-form-item>
    <el-form-item
        prop="invitationCode"
    >
      <div class="form-item">
        <el-input
            v-model="localForm.invitationCode"
            type="number"
            class="form-item-input no-number invitationCode"
            placeholder="请输入邀请码"
            autocomplete="off"
            v-elInput
        />
<!--        <div class="tips-text">购买满一年，邀请人与被邀请人均可<span class="main">额外增送一个月</span></div>-->
      </div>
    </el-form-item>

    <div class="shop-konw">
      <el-checkbox v-model="isRead" size="large">
        <span>我已阅读并同意</span>
        <a class="book" target="_blank"
           href="https://oss.musaemotion.com//WEB/assets/html/privacyPolicy.html">《隐私协议》</a>
        <a class="book" target="_blank"
           href="https://oss.musaemotion.com//WEB/assets/html/termsService.html">《服务协议》</a>
      </el-checkbox>
    </div>
    <div class="btn-box">
      <button type="button" class="wy-button next-btn login" :disabled="canClick" @click="submitForm(formRef)">下一步
      </button>
    </div>
  </el-form>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, reactive, ref, watch} from "vue";
import {codeBtn} from "@utils/tool/getCode";
import {codeSend, registerStepOne} from '@utils/api/person/index.js';
import {formRules} from "../../config"
import {useRoute} from "vue-router";

const route = useRoute()
const formRef = ref(null)
const isRead = ref(false)
const rules = reactive({
  phone: formRules.phone,
  code: formRules.code,
  invitationCode: formRules.invitationCode,
});
const { str , getCode , isOK } = codeBtn()
const props = defineProps({
  form: {
    type: Object,
    default: () => {}
  }
})
const {form} = props;
const localForm = reactive({
  phone:"",
  code:"",
  invitationCode: "",
});
const canClick = computed(() => !localForm.phone || !localForm.code)
const emit = defineEmits(['update:form', 'confirm'])
const handleClickGetCode = ()=>{
  if (!isOK.value) return
  formRef.value.validateField('phone', (errorMsg) => {
    if (!errorMsg) return
    codeSend({phone: localForm.phone, placeId: 5}).then((res) => {
      if (res.code !== 0) return proxy.$message.error(res.msg)
      proxy.$message.success('验证码发送成功')
      getCode()
    })
  })
}

const submitForm = (formEl) => {
  if (!isRead.value) return proxy.$message.error('请阅读并同意《服务协议》、《隐私协议》')
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      //请求修改密码接口
      registerStepOne({
        mobile: localForm.phone,
        vcode: localForm.code,
      }).then(res => {
        if (res.code !== 0) return proxy.$message.error(res.msg)
        emit('confirm')
      })
    } else {
      return false;
    }
  });
}

const {proxy} = getCurrentInstance();

watch(()=>localForm,(newValue)=>{
  emit('update:form', newValue )
},{deep:true})
onMounted(() => {
  if (route.query.inviteCode) {
    localForm.invitationCode = route.query.inviteCode
  }
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "registerForm",
});
</script>
<style lang="scss"  scoped>
@import url(../../register/common.scss);

.shop-improve-from {
  .el-form-item {
    margin-top: 24px;

    &:first-child {
      margin-top: 55px;
    }
  }

  .next-btn {
    margin-top: 66px;
    width: 100%;
    height: 80px;
    font-size: 18px;
  }
  .form-item{
    width: 100%;
    position: relative;
    margin-top: 22px;

    .tips-text {
      position: absolute;
      right: 0;
      top: -40px;
      font-size: 14px;
      color: #697691;

      .main {
        color: #9CA5B8;
      }
    }
    .code-btn{
      color: #466AFE;
      height: 80px;
      line-height: 80px;
      position: absolute;
      right: 24px;
      font-size: 18px;
      top: 0;
      cursor: pointer;
      user-select: none;
      &.disabled{
        color: #697691;
        cursor: not-allowed;
      }
    }
  }
}
</style>
