<template>
  <!--  商城注册页面-->
  <div class="shop-register">
    <ShopLogo type="index" class="logo shop-logo"></ShopLogo>
    <div class="shop-middle-content" v-if='isReg'>
      <div class="shop-welcome">WELCOME</div>
      <div class="shop-register-title">欢迎使用纸盒</div>
      <ShopRegisterForm v-model:form="form" @confirm="next"></ShopRegisterForm>
      <hash-count/>
    </div>
    <div class="shop-middle-content" v-else>
      <h2 class="sub-title">填写信息，即可体验纸盒</h2>
      <improve class="mt_48" :form="form"></improve>
     <hash-count/>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref ,onMounted} from "vue";
import ShopRegisterForm from "./components/registerForm.vue";
import Improve from "./components/improve.vue";
import HashCount from './components/hashCount.vue';
import ShopLogo from "@views/shop/components/logo.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const form = ref({
  phone:"",
  code:"",
  pwd: ''
})
const isReg = ref(true)

const next = () => {
  isReg.value = !isReg.value
}

onMounted(() => {
  const data = route.query;
  if(data.loginPhone){
    form.value.phone = data.loginPhone
    isReg.value = false
  }
})


</script>
<script>
import {defineComponent} from "vue";
import hashCount from "./components/hashCount.vue";

export default defineComponent({
  components: { hashCount },
  name: "register",
});
</script>

<style lang="scss" src="./index.scss" scoped>
</style>
<style lang="scss" src="./common.scss" scoped>
</style>
