<template>
  <div class="foot-text">
    <span class="text">已有账户?</span>
    <span class="book cur_p" @click="goLogin">直接登录</span>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter()
const goLogin = () => {
  router.push('/shop/login')
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "hashCount",
});
</script>

<style lang="scss" scoped>
.foot-text{
  text-align: center;
  margin-top: 24px;
  .text{
    color: $shopFsColor3;
    margin-right: 8px;
  }
}
.book{
  color: $mainColor;
}
</style>
